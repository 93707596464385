<template>
  <div
    class="grid h-screen w-screen grid-cols-[260px_1fr] bg-primary"
    data-cy-hub-layout
  >
    <aside class="flex flex-col justify-between px-4 py-8">
      <div>
        <organization-menu-entry
          v-if="organization"
          :organization="organization"
        />

        <app-navigation-menu
          class="mt-12 self-stretch"
          :items="navigationItems"
        />
      </div>

      <div class="grid gap-8">
        <support-dialog />

        <a :href="websiteBaseUrl" target="_blank">
          <app-image
            alt="Pollen logo"
            :height="30"
            src="/images/logo_full_white.svg"
          />
        </a>
      </div>
    </aside>

    <main
      class="grid overflow-hidden rounded-tl-[32px] bg-white"
      :class="{ 'grid-rows-[auto_1fr]': $slots.header }"
    >
      <header
        v-if="$slots.header || $slots.headerActions"
        class="relative z-10 flex items-center gap-4 rounded-tl-[32px] bg-white px-8 py-4 shadow-lg"
      >
        <app-icon-button
          v-if="backRoute"
          icon="ph:arrow-left"
          :label="$t('app.cta.back')"
          :to="backRoute"
        />

        <div class="flex-auto">
          <slot name="header" />
        </div>

        <div class="flex shrink-0 justify-end">
          <slot name="headerActions" />
        </div>
      </header>

      <div class="overflow-auto p-8">
        <slot />
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import type { NavigationMenuItem } from "@pollen/ui-library";

import type { RouteLocationRaw } from "#vue-router";
import { useCurrentOrganization } from "~/business-areas/organization/composables/current-organization.hook";

defineProps<{
  backRoute?: RouteLocationRaw;
}>();

const { organization, hasCreditPacks } = useCurrentOrganization();

const { t } = useI18n();
const navigationItems: NavigationMenuItem[] = [
  {
    label: t("session.navigation_label"),
    to: {
      name: "organizationSlug-sessions",
      params: { organizationSlug: organization.value!.slug },
    },
    icon: "ph:graduation-cap",
  },
  {
    label: t("satisfaction.navigation_label"),
    to: {
      name: "organizationSlug-satisfaction",
      params: { organizationSlug: organization.value!.slug },
    },
    icon: "ph:star",
  },
  ...(hasCreditPacks.value
    ? ([
        {
          label: t("credits.navigation_label"),
          icon: "ph:coins",
          to: {
            name: "organizationSlug-credits",
            params: {
              organizationSlug: organization.value!.slug,
            },
          },
        },
      ] as const)
    : []),

  {
    label: "Catalogue",
    href: "/auth/bridge/website?redirect=/search",
    icon: "ph:books",
    appendIcon: "ph:arrow-square-out",
  },
];

const {
  public: {
    apps: { websiteBaseUrl },
  },
} = useRuntimeConfig();
</script>
